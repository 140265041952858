<script>
import { getExtName, privateOss } from '@/utils'
import { getAuthorizatin } from '@/utils/auth'

export default {
  name: 'DownloadMixin',
  methods: {
    /**
     * 下载私有oss的文件
     * @param path
     */
    downloadLarge(path) {
      window.location.href = privateOss(path)
    },
    /**
     * 下载没有认证的文件(通常是公有的oss文件)
     * @param path
     * @param filename
     */
    download(path, filename) {
      const extName = getExtName(path)
      fetch(privateOss(path)).then(res => {
        res.blob().then(blob => {
          const a = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = filename + '.' + extName
          a.click()
          window.URL.revokeObjectURL(url)
        })
      })
    },
    downloadCrashLog(path) {
      const fileName = path.split('/').pop()
      fetch(privateOss(path)).then(res => {
        res.blob().then(blob => {
          const a = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = fileName
          a.click()
          window.URL.revokeObjectURL(url)
        })
      })
    },
    /**
     * 下载后端接口直接输出的文件
     * @param path
     * @param filename
     */
    downloadWithAuth(path, filename) {
      return new Promise((resolve, reject) => {
        fetch(path, {
          headers: {
            Authorization: getAuthorizatin()
          }
        }).then((res, a) => {
          if (res.status !== 200) {
            reject(res)
          } else {
            res.blob().then(blob => {
              const a = document.createElement('a')
              const url = window.URL.createObjectURL(blob)
              a.href = url
              a.download = filename
              a.click()
              window.URL.revokeObjectURL(url)
              resolve()
            })
          }
        })
      })
    }
  }
}
</script>
