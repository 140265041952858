export default {
  /* 路由 */
  route: {
    'mobile-device': '移動機',
    'mobile-device-list': '單一機器',
    'mobile-device-statistics': '整體',
    'desk-device': '桌機',
    'desk-device-list': '單一機器',
    'desk-device-statistics': '整體',
    'app-user-list': '手機APP',
    'hot-song': '歌曲',
    'hot-song-list': '每週排行榜',
    'hot-song-click': '點歌總數量',
    'yt-failed': 'YT失效歌曲',

  },
  /* 常用的 */
  common: {
    edit: '編輯',
    new: '新增',
    newItem: '新增項目',
    delete: '刪除',
    deleteConfirm: '確認刪除該資源嗎？',
    forceDeleteConfirm: '刪除後資料將永久消失，確認是否刪除？',
    yes: '是',
    no: '否',
    more: '更多',
    history: '歷史紀錄',
    historyTieleDesc: '可重新上架：按下[ 準備重新上架 ]後，於上一頁確認排序＋確認發布，點擊率將歸0重新計算。按下左方垃圾桶icon：此項目將永久刪除。項目自下架日隔天起算，最多只保存三年。',
    click: '點擊率',
    hint: '提示',
    fromTo: '起迄',
    from: '開始',
    uploadBnt: '按此上傳',
    upload: '上傳',
    'return': '返回',
    publish: '發布',
    cancel: '取消',
    confirm: '確認',
    makeSurePublish: '如未按下[ 確認發布 ] ，編輯過的資料都將不保存。',
    type: '種類',
    image: '圖片',
    video: '影片',
    text: '文字',
    refresh: '刷新',
    leave: '離開',
    giveUp: '將視同放棄此次編輯',
    completeForm: '請填寫必填項目',
    leaveConfirm: '離開此頁後，編輯內容無法暫存。如確認上傳，離開前請先按下「確認發布」按鈕。',
    rereshConfirm: '刷新此頁後，編輯內容無法暫存。如確認上傳，刷新前請先按下「確認發布」按鈕。',
    dragAble: '拖曳左方icon可以排序（優先順序：由上到下）。',
    restore: '準備重新上架',
    restoreConfirmTitle: '確認重新上架',
    clickZero: '點擊率將歸0',
    publishNow: '上傳後立即發布',
    status: '狀態',
    action: '動作',
    rules: {
      required: 'required',
      format_error: '格式錯誤',
      firmware_version_exists: 'Firmware 版本號已經存在'
    },
    download: '下載',
    count: '次數',
    offline: '下架',
    online: '上架',
    offlineConfirmMessage: '下架後資料將一併刪除，確認是否下架？',
    success: '成功',
    nonRequired: '（非必填）',
    invalidCredentials: '用戶名或者密碼錯誤',
    busy: '後端服務器繁忙，請稍後再試',
    fileTooLarge: '文件太大',
    email: 'Email',
    createdAt: '創建時間',
    disable: '禁用',
    enable: '啟用',
    validator: {
      url: '請輸入正確的網址'
    },
    female: '女',
    male: '男',
    unknow: '未定義',
    bool: ['N', 'Y']
  },
  common1: {
    female: '女',
    male: '男',
    unknow: '',
  },
  views: {
    page_titles: {
      star: {
        title: '音圓主打星',
        desc: '新專輯主打的幾首歌曲、主題節慶歌曲。建議每台最多5個歌庫。'
      },
      message: {
        title: '消息',
        desc: '活動、廣告、公告、新聞等。建議最多5項。'
      },
      songlist: {
        title: '推薦歌庫',
        desc: '可選擇現有歌庫、建立臨時歌庫。建議最多5項。'
      },
      teach: {
        title: '教學',
        desc: '功能介紹QRcode影片或說明頁面。建議最多5項。'
      }
    },
    login: {
      desc: '登入者請自行保管妥您的帳號及密碼，切勿提供給任何第三人，並遵循「個資法」之相關規定，未經官方授權，禁止提供相關個資外流；登入者如未經授權同意，違反此公告協議須自行負擔公司懲處及相關法律責任。',
      email: '請輸入公司Email',
      pwd: '請輸入password',
      rememberMe: 'Remember me',
      signIn: 'Sign In',
      singOut: '登出'
    },
    home: {
      log: '推播日誌',
      tuibo: '推播',
      service: '服務',
      tuiboTabs: {
        kalaok: '伴唱機端',
        phone: '手機APP'
      },
      serviceTabs: {
        firmware: 'Firmware',
        DownloadSongs: '雲端下載歌曲',
        yt: 'YT資源',
        users: '資安管理',
        songs: '歌曲管理'
      },
      kalaok: {
        mainDir: '主目錄',
        other: '其他資訊',
        warmUp: '暖場',
        hello: '問候＆訊息',
        ytd: 'YTD',
        chartlet: '官方貼圖'
      },
      hello: {
        helloText: '問候語＆開始時間'
      },
      firmware: {
        tableName: '版本紀錄表',
        new: 'Upload Firmware',
        model: 'Model',
        machineModel: '機種',
        publish_at: '發布時間',
        firmware: '檔案',
        version: '版本號',
        note: '說明',
        force: '強制升級',
        forceComment: '強制所有用戶都升級成此版fw',
        innerUse: '內部測試用',
        status: {
          standby: '待命',
          online: '在線',
          offline: '下線',
          test: '測試'
        }
      },
      downloadSongs: {
        Song: '原聲原影',
        Midi: '音圓歌集',
        status: {
          standby: '待命',
          online: '在線',
          stash: '暫存'
        }
      },
      downloadMidis: {
        coverSize: '410px / 190px'
      },
      user: {
        tableName: '账号管理',
        User: '帳號管理',
        Ip: 'IP白名單',
        status: {
          1: '正常',
          2: '已禁用'
        }
      },
      quick: {
        func: '快捷',
        quicks: {
          none: '', // 用来在还未加载数据时显示空字符串
          practice: '練習',
          iyCloud: '音圓雲端下載',
          userCollect: '我的收藏',
          aboutUs: '關於音圓',
          mainDir: '主目錄',
          smartSoundBox: '藍牙音箱',
          appMode: '網頁/娛樂'
        },
        coverSize: '410px / 396px'
      },
      hotSongs: {
        hot_guoyu: '國語週排行',
        hot_taiyu: '台語週排行'
      }
    },
    star: {
      name: '歌庫名稱',
      csv: 'CSV歌单',
      coverSize: '836px / 396px'
    },
    message: {
      dateOnTop: '日期顯示於文章頂部',
      address: '消息網址',
      img: '內容大圖',
      qrcode: '上傳QRcode',
      title: '標題',
      author: '作者',
      content: '內文',
      preview: '成果範例',
      coverSize: '410px / 396px'
    },
    familyMix: {
      coverSize: '410px / 190px'
    },
    songList: {
      song_book: '目前曲庫',
      coverSize: '410px / 396px'
    },
    teach: {
      coverSize: '410px / 190px'
    },
    device: {
      'for': {
        business_show: '商務表演使用',
        choir: '歌唱班成員/社團',
        family: '一般家庭使用',
        teacher: '老師教學/歌手培訓',
        tour_bus_other: '遊覽車/其它',
        unknow: ''
      },
      cities: {
        unknow: '',
        A: '臺北市',
        B: '臺中市',
        C: '基隆市',
        D: '臺南市',
        E: '高雄市',
        F: '新北市',
        G: '宜蘭縣',
        H: '桃園市',
        J: '新竹縣',
        K: '苗栗縣',
        M: '南投縣',
        N: '彰化縣',
        P: '雲林縣',
        Q: '嘉義縣',
        T: '屏東縣',
        U: '花蓮縣',
        V: '臺東縣',
        X: '澎湖縣',
        W: '金門縣',
        Z: '連江縣',
        I: '嘉義市',
        O: '新竹市'
      },
      columns: {
        birthdate: '生日',
        city: '地區',
        'for': '伴唱機使用情境',
        gender: '性別',
        name: '姓名',
        phone: '電話',
        register_at: '註冊時間'
      },
      guideds: [
        '人聲導唱',
        '樂器導唱',
        '無(純伴奏)',
        '自錄導唱',
        '人聲消除'
      ]
    }
  }
}
